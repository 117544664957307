@font-face {
    font-family: 'PP Woodland';
    src: url('../fonts/PPWoodland-Ultralight.eot');
    src: url('../fonts/PPWoodland-Ultralight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PPWoodland-Ultralight.woff2') format('woff2'),
        url('../fonts/PPWoodland-Ultralight.woff') format('woff'),
        url('../fonts/PPWoodland-Ultralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}    
@font-face {
    font-family: 'PP Woodland';
    src: url('../fonts/PPWoodland-Regular.eot');
    src: url('../fonts/PPWoodland-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PPWoodland-Regular.woff2') format('woff2'),
        url('../fonts/PPWoodland-Regular.woff') format('woff'),
        url('../fonts/PPWoodland-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'PP Woodland';
    src: url('../fonts/PPWoodland-Medium.eot');
    src: url('../fonts/PPWoodland-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PPWoodland-Medium.woff2') format('woff2'),
        url('../fonts/PPWoodland-Medium.woff') format('woff'),
        url('../fonts/PPWoodland-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'PP Woodland';
    src: url('../fonts/PPWoodland-Bold.eot');
    src: url('../fonts/PPWoodland-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PPWoodland-Bold.woff2') format('woff2'),
        url('../fonts/PPWoodland-Bold.woff') format('woff'),
        url('../fonts/PPWoodland-Bold.ttf') format('truetype');
    font-weight: bold;    
    font-style: normal;
    font-display: swap;
}    

@font-face {
    font-family: 'Averta';
    src: url('../fonts/AvertaCY-Regular.eot');
    src: url('../fonts/AvertaCY-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvertaCY-Regular.woff2') format('woff2'),
        url('../fonts/AvertaCY-Regular.woff') format('woff'),
        url('../fonts/AvertaCY-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Averta';
    src: url('../fonts/AvertaCY-Semibold.eot');
    src: url('../fonts/AvertaCY-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvertaCY-Semibold.woff2') format('woff2'),
        url('../fonts/AvertaCY-Semibold.woff') format('woff'),
        url('../fonts/AvertaCY-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Averta';
    src: url('../fonts/AvertaCY-Bold.eot');
    src: url('../fonts/AvertaCY-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvertaCY-Bold.woff2') format('woff2'),
        url('../fonts/AvertaCY-Bold.woff') format('woff'),
        url('../fonts/AvertaCY-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


#root{
    display:flex;
    flex-flow:column nowrap;
    min-height:100vh;
}
